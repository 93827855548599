<ng-container *ngIf="state$ | async as state">
  <div class="spinner-container" *ngIf="state.isFetching">
    <alg-loading size="small"></alg-loading>
  </div>
  <alg-error
    *ngIf="state.isError"
    class="light"
    icon="ph-duotone ph-warning-circle"
    buttonStyleClass="alg-button primary refresh-button light-border"
    i18n-message message="Error while loading the activities"
    [showRefreshButton]="true"
    refreshButtonType="refresh"
    (refresh)="refresh($event)"
  ></alg-error>
  <div class="empty-list" *ngIf="state.isReady && state.data.length === 0" i18n>
    There are no activities linked to this
    <ng-container *ngIf="observedGroupRoute$ | async as observedGroupRoute">
      {{ (observedGroupRoute | isUser).toString() | i18nSelect: { true: 'user', other: 'group' } }}.
    </ng-container>
  </div>
  <ul class="list" *ngIf="state.isReady && state.data.length > 0">
    <li class="list-item" *ngFor="let item of state.data">
      <a class="alg-link dots item-link" [routerLink]="item.activity | rawItemRoute | url">
        {{ item.activity.string.title }} ({{ item.name }})
      </a>
    </li>
  </ul>

</ng-container>
