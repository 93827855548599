<div class="container">
  <button
    class="alg-button-icon tab-arrow tab-prev prev-button"
    type="button"
    icon="ph-bold ph-caret-left"
    pButton
    (click)="onPrev()"
    *ngIf="showPrevButton"
  ></button>
  <ng-scrollbar
    viewClass="alg-top-bar-scrollbar"
    trackClass="alg-top-bar-scrollbar-no-track"
    track="horizontal"
    [autoWidthDisabled]="false"
    [sensorDebounce]="1"
    appearance="standard"
  >
    <ul class="tabs" [ngClass]="styleClass" *ngrxLet="tabs$ as tabs">
      <li class="tabs-item" [ngClass]="tab.styleClass" *ngFor="let tab of tabs">
        <a class="tabs-item-link" [routerLink]="tab.routerLink" (click)="onChange(tab)">{{ tab.label }}</a>
      </li>
    </ul>
  </ng-scrollbar>
  <button
    class="alg-button-icon tab-arrow tab-next next-button"
    type="button"
    icon="ph-bold ph-caret-right"
    pButton
    (click)="onNext()"
    *ngIf="showNextButton"
  ></button>
</div>
