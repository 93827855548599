<div class="widget" *ngrxLet="{
    state: state$,
    isWsOpen: isWsOpen$,
    threadStatus: threadStatus$,
    hasNoMessages: hasNoMessages$,
    isCurrentUserThreadParticipant: isCurrentUserThreadParticipant$,
    isMine: isMine$,
  } as thread"
>
  <div class="widget-loading" *ngIf="thread.isWsOpen && thread.state.isFetching">Loading...</div>
  <div class="widget-error" *ngIf="thread.isWsOpen && thread.state.isError">Error!</div>
  <div class="ws-error" *ngIf="!thread.isWsOpen" i18n>The connection with the forum has been lost, try to refresh the current page.</div>
  <div class="no-events" *ngIf="thread.state.isReady && thread.state.data.length === 0; else eventsBlock" i18n>
    There are currently no events
  </div>
  <ng-template #eventsBlock>
    <div class="widget-body" *ngIf="thread.isWsOpen">
      <ng-container *ngIf="!thread?.isMine">
        <ng-container *ngIf="participantUser$ | async as participantUser">
          <div class="user-indicator-container" *ngIf="participantUser.name">
            <div class="user-indicator">
              <span i18n>You are helping</span>
              <a class="alg-link user-indicator-link" [routerLink]="[ '/groups/users', participantUser.id ]">{{ participantUser.name }}</a>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="widget-scroll" #messagesScroll *ngrxLet="{ id: threadId$ | async, userCache: userCache$ | async, canCurrentUserLoadAnswers: canCurrentUserLoadAnswers$ | async } as threadMetadata">
        <div class="no-messages" *ngIf="thread.isCurrentUserThreadParticipant && thread.threadStatus?.isReady && !thread.threadStatus?.data?.open && thread.hasNoMessages; else messagesBlock" i18n>
          In this panel, you can request help from other users about the content you are on.
          People who have already validated this activity will see your request and be able to answer.
        </div>
        <ng-template #messagesBlock>
          <ng-container *ngIf="threadMetadata.id">
            <alg-thread-message
              class="thread-message"
              [threadId]="threadMetadata.id"
              [event]="event"
              [userCache]="threadMetadata.userCache ?? []"
              [canCurrentUserLoadAnswers]="threadMetadata.canCurrentUserLoadAnswers ?? false"
              [itemRoute]="threadMetadata.id ? ({ id: threadMetadata.id.itemId, type: 'Task' } | rawItemRoute) : undefined"
              *ngFor="let event of thread.state.data"
            ></alg-thread-message>
          </ng-container>
        </ng-template>
        <ng-container *ngIf="threadMetadata.id as threadId">
          <div class="footer-panel" *ngrxLet="!!(thread.threadStatus && !!thread.threadStatus.data && !!thread.threadStatus.data.open) as isThreadOpened">
            <form class="send-form" [formGroup]="form" (ngSubmit)="sendMessage(threadId, isThreadOpened)">
              <textarea
                class="textarea"
                placeholder="Send a message..."
                pInputTextarea
                formControlName="messageToSend"
                [rows]="1"
                [autoResize]="true"
                (keydown.enter)="$event.preventDefault(); sendMessage(threadId, isThreadOpened)"
                #messageToSendEl
              ></textarea>
              <button
                class="alg-button-icon primary-color rounded send-button"
                type="submit"
                icon="pi pi-send"
                pButton
                [disabled]="!form.get('messageToSend')?.value?.trim() || !!(disableControls$ | async)"
              ></button>
            </form>
            <div class="thread-status">
              <div class="thread-status-caption" >
                <ng-container *ngIf="thread.threadStatus?.data?.open">
                  <span i18n>This thread is open</span>
                </ng-container>
                <ng-container *ngIf="thread.threadStatus?.data?.open === false">
                  <span i18n>This thread is closed</span>
                </ng-container>
                <ng-container *ngIf="thread.threadStatus?.isFetching">
                  <span i18n>Loading thread status...</span>
                </ng-container>
                <ng-container *ngIf="thread.threadStatus?.isError">
                  <span i18n>Error while loading thread info</span>
                </ng-container>
                <ng-container *ngIf="thread.threadStatus === undefined">
                  <span i18n>Could not load thread</span>
                </ng-container>
              </div>
              <div
                *ngrxLet="!!thread.threadStatus && !!thread.threadStatus.data && ((thread.threadStatus.data.open && thread.threadStatus.data.canClose) || (!thread.threadStatus.data.open && thread.threadStatus.data.canOpen)) as canSwitch"
                [pTooltip]="threadStatusTooltip"
                [tooltipDisabled]="canSwitch"
                tooltipPosition="top"
                tooltipStyleClass="alg-tooltip"
              >
                <button
                  type="button"
                  class="alg-button primary small"
                  [disabled]="!canSwitch"
                  pButton
                  (click)="changeThreadStatus({ open: !thread.threadStatus.data.open, threadId: threadId })"
                  *ngIf="thread.threadStatus && thread.threadStatus.data"
                >
                  <span *ngIf="thread.threadStatus.data.open; else openThreadCaption" i18n>Close this thread</span>
                  <ng-template #openThreadCaption>
                    <span i18n>Open this thread</span>
                  </ng-template>
                </button>
              </div>
              <ng-template #threadStatusTooltip>
                <span *ngIf="thread.threadStatus?.data?.open; else notOpenedTooltipCaption" i18n>
                  Only the user of the thread can close it.
                </span>
                <ng-template #notOpenedTooltipCaption>
                  <span i18n>You are not allowed to open this thread.</span>
                </ng-template>
              </ng-template>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>

