<div class="controls-zone" [ngClass]="drawLeftBorder ? 'with-left-border' : 'no-left-border'" *ngrxLet="session$ as session">
  <ng-container
    *ngIf="session && !session.tempUser; then authenticated else notAuthenticated"
  ></ng-container>

  <ng-template #authenticated>
    <alg-top-right-menu [styleClass]="topRightMenuStyleClass"></alg-top-right-menu>
  </ng-template>

  <ng-template #notAuthenticated>
    <alg-language-picker
      class="lang-button"
      [styleClass]="languagePickerStyleClass"
      *ngIf="languages.length > 1"
    ></alg-language-picker>
    <button
      class="alg-button-icon primary-color no-bg login"
      icon="ph-bold ph-power"
      (click)="login()"
      pButton
    ></button>
  </ng-template>
</div>
