<div class="container" *ngIf="data !== undefined">
  <alg-left-menu-back-button (close)="close.emit()">
    <ng-container *ngIf="data.length < 10; else moreResultsCaption">
      {{ data.length | i18nPlural: {
        '=0': 'No result found',
        '=1': '# result found',
        'other': '# results found'
      } }}
    </ng-container>
    <ng-template #moreResultsCaption>
      <span i18n>First {{ data.length }} results</span>
    </ng-template>
  </alg-left-menu-back-button>
</div>

<div class="container" *ngIf="data === undefined">
  <alg-message-info class="search" icon="ph-duotone ph-warning-circle" i18n>
    Type at least 3 letters to search content
  </alg-message-info>
</div>

<div class="container" *ngIf="data !== undefined && data.length === 0">
  <alg-message-info icon="ph-duotone ph-warning-circle" i18n>
    No results
  </alg-message-info>
</div>

<ng-container *ngFor="let item of data;">
  <div class="container">
    <ul class="result-list alg-search-result">
      <li class="result-list-item" (mouseleave)="onMouseLeave($event)">
        <a class="result-list-link" [routerLink]="item | rawItemRoute | url" routerLinkActive="selected">
          <i
            class="result-list-icon ph-duotone"
            [ngClass]="{
            'ph-files': item.type === 'Task',
            'ph-folders': item.type === 'Chapter'
          }"
          ></i>
          <span class="result-list-link-caption" (mouseenter)="onMouseEnter($event, item.id, contentRef)" #contentRef>
            <span [innerHTML]="titleHighlight" *ngIf="item.titleHighlight as titleHighlight; else noTitleHighlight"></span>
            <ng-template #noTitleHighlight>{{ item.title }}</ng-template>
          </span>
        </a>
        <div class="result-list-description-wrapper" *ngIf="item.highlights.length > 0">
          <p class="result-list-description" *ngFor="let highlight of item.highlights" [innerHTML]="highlight"></p>
        </div>
      </li>
    </ul>
  </div>
</ng-container>

<p-overlayPanel
  styleClass="alg-path-suggestion-overlay"
  #op
>
  <ng-container *ngIf="op.overlayVisible">
    <alg-path-suggestion [itemId]="(showOverlay$ | async)?.itemId" (mouseleave)="closeOverlay()"></alg-path-suggestion>
  </ng-container>
</p-overlayPanel>

