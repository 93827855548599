<ng-container *ngIf="activeTab$ | async as activeTab;">
  <ng-container *ngIf="currentUser$ | async as currentUser">
    <div class="tab-view-container" *ngIf="!skillsDisabled || activeTab.index === 2 || !currentUser.tempUser">
      <div class="main-menu">
        <button
          class="main-menu-button active"
          [ngClass]="{ 'active': activeTab.index === 0, 'compact': currentLanguage === 'fr' }"
          (click)="onSelectionChangedByIdx(0)"
        >
          <div class="main-menu-icon-wrapper">
            <i class="ph ph-presentation main-menu-icon"></i>
          </div>
          <span class="main-menu-title" i18n="|Tab name">Content</span>
        </button>

        <button
          class="main-menu-button"
          [ngClass]="{ 'active': activeTab.index === 1, 'compact': currentLanguage === 'fr' }"
          *ngIf="!skillsDisabled"
          (click)="onSelectionChangedByIdx(1)"
        >
          <div class="main-menu-icon-wrapper">
            <i class="ph ph-graduation-cap main-menu-icon"></i>
          </div>
          <span class="main-menu-title" i18n="|Tab name">Skills</span>
        </button>

        <button
          class="main-menu-button"
          [ngClass]="{ 'active': activeTab.index === 2, 'compact': currentLanguage === 'fr' }"
          data-cy="main-menu-group-btn"
          (click)="onSelectionChangedByIdx(2)"
          *ngIf="activeTab.index === 2 || !currentUser.tempUser"
        >
          <div class="main-menu-icon-wrapper">
            <i class="ph ph-users main-menu-icon"></i>
          </div>
          <span class="main-menu-title" i18n="|Tab name">Groups</span>
        </button>
      </div>
    </div>
  </ng-container>

  <hr class="divider">
  <ng-container *ngrxLet="searchResultState$ as searchResultState">
    <ng-container *ngIf="searchQuery.length > 0 else navTree">
      <ng-container *ngIf="searchResultState.isFetching || searchResultState.isError || !searchResultState.data">
        <div class="container">
          <alg-left-menu-back-button (close)="closeSearch.emit()">
            Searching: "{{ searchQuery }}"
          </alg-left-menu-back-button>
        </div>
        <alg-loading class="alg-flex-1" *ngIf="searchResultState.isFetching"></alg-loading>
        <alg-error
          class="alg-flex-1"
          i18n-message message="Unable to perform the search."
          icon="ph-duotone ph-warning-circle"
          [showRefreshButton]="true"
          buttonCaption="Retry"
          (refresh)="retrySearch()"
          *ngIf="searchResultState.isError"
        ></alg-error>
      </ng-container>

      <div class="alg-flex-1 scroll-wrapper" *ngIf="searchResultState.isReady">
        <div class="scroll-container">
          <ng-scrollbar [sensorDebounce]="1">
            <alg-left-search-result [data]="searchResultState.data" (close)="closeSearch.emit()"></alg-left-search-result>
          </ng-scrollbar>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #navTree>
    <ng-container *ngIf="navTreeServices[activeTab.index]!.state$ | async as state">
      <alg-loading class="alg-flex-1" *ngIf="state.isFetching && !state.data"></alg-loading>
      <alg-error
        class="alg-flex-1"
        i18n-message message="Unable to load the list."
        icon="ph-duotone ph-warning-circle"
        [showRefreshButton]="true"
        buttonCaption="Retry"
        (refresh)="retryError(activeTab.index)"
        *ngIf="state.isError"
      ></alg-error>

      <div class="watching-warning" *ngIf="state.isReady && (isObserving$ | async) && [0, 1].includes(activeTab.index)">
        <alg-observation-bar
          [caption]="!!(isNarrowScreen$ | async) ? undefined : observationModeCaption"
          [showTooltip]="true"
        ></alg-observation-bar>
      </div>

      <div class="alg-flex-1 scroll-wrapper" *ngIf="state.data">
        <div class="scroll-container">
          <ng-scrollbar [sensorDebounce]="1">
            <alg-left-nav-tree
              [data]="state.data"
              [elementType]="$any(['activity', 'skill', 'group'][activeTab.index])"
            ></alg-left-nav-tree>
          </ng-scrollbar>
        </div>
      </div>
    </ng-container>
  </ng-template>

</ng-container>
