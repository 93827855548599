<div class="wrapper" [ngClass]="{ 'only-icon-mode': onlyIcon }" *ngIf="observedGroup$ | async as observedGroup">
  <div class="left-section alg-flex-1">
    <i
      class="left-icon alg-secondary-color ph-duotone ph-eye"
      [pTooltip]="observationModeTooltip"
      [tooltipDisabled]="!showTooltip"
      [positionLeft]="5"
      tooltipStyleClass="alg-tooltip observation-mode"
    ></i>
    <ng-template #observationModeTooltip>
      <span *ngIf="(observedGroup.route | isUser); else groupName" i18n>
        Only the content visible by the user you are observing ({{ observedGroup.name }}) is shown.
      </span>
      <ng-template #groupName>
        Only the content visible by the group you are observing ({{ observedGroup.name }}) is shown.
      </ng-template>
    </ng-template>
    <span class="title" *ngIf="!onlyIcon">
      @if(caption) {
        <span class="alg-secondary-color">{{ caption }}</span>
      } @else if(observedGroup.name) {
        <a
          class="link alg-link hover-underline alg-secondary-color"
          [routerLink]="observedGroup.route | groupLink"
        >
          {{ caption || observedGroup.name }}
        </a>
      }
    </span>
  </div>
  <button pButton type="button" icon="ph ph-x" class="alg-button-icon secondary-color no-bg" (click)="onCancelClick()"></button>
</div>
