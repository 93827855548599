<div class="back-button-container cursor-pointer" (click)="close.emit()">
  <button
    class="alg-button-icon back-button rounded"
    [icon]="icon"
    pButton
  ></button>
  <p class="back-button-title alg-flex-1">
    <ng-content></ng-content>
  </p>
</div>
