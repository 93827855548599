<div class="wrapper">
  <div class="icon-container" *ngIf="icon">
    <i class="icon" [ngClass]="icon"></i>
  </div>
  <div class="message-container">
    <span class="message" [ngClass]="{ 'no-margin-top': !icon }">
      {{ message }}
      <ng-content *ngIf="!message"></ng-content>
    </span>
  </div>
  <button
    [class]="buttonStyleClass || 'alg-button primary refresh-button'"
    pButton
    (click)="onRefresh($event)"
    *ngIf="showRefreshButton && !link"
  >{{ buttonCaption }}</button>
  <a
    class="alg-button primary refresh-button"
    pButton
    [routerLink]="link"
    *ngIf="link"
  >{{ buttonCaption }}</a>
</div>
