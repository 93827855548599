<ng-container *ngIf="state$ | async as state">
  <div class="spinner-container" *ngIf="state.isFetching">
    <alg-loading size="small"></alg-loading>
  </div>
  <alg-error
    *ngIf="state.isError"
    class="alg-flex-1 light"
    icon="ph-duotone ph-warning-circle"
    i18n-buttonCaption buttonCaption="Retry"
    [showRefreshButton]="$any(state.error).status !== 403"
    buttonStyleClass="alg-button primary refresh-button light-border"
    (refresh)="refresh()"
  >
    <ng-container *ngIf="$any(state.error).status === 403; else otherError;">
      <span class="no-access" i18n>There is currently no way for you to access this content.</span>
    </ng-container>
    <ng-template #otherError>
      <span i18n>Error while loading path suggestion</span>
    </ng-template>
  </alg-error>
  <ng-container *ngIf="state.isReady">
    <ng-container *ngIf="state.data; else noPath">
      <ng-container *ngIf="state.data?.length === 1 && state.data?.[0]?.length === 1; else pathsSection">
        <alg-error class="alg-flex-1 light" *ngIf="state.data?.[0]?.[0] as rootItem">
          <span class="error-caption" i18n>
            This content is one of your root
            {{ rootItem.type | i18nSelect : {
              Skill: 'skill',
              other: 'activity'
            } }}
          </span>
        </alg-error>
      </ng-container>
      <ng-template #pathsSection>
        <p class="breadcrumbs-title" i18n>This content can be found in...</p>
        <div class="breadcrumbs-container" *ngFor="let breadcrumbs of state.data">
          <i class="ph-duotone ph-link breadcrumbs-prefix-icon"></i>
          <ul class="breadcrumbs">
            <li class="breadcrumbs-item" *ngFor="let item of breadcrumbs">
              <a class="breadcrumbs-item-link alg-link" [routerLink]="item.url">
                {{ item.title }}
              </a>
            </li>
          </ul>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #noPath>
      <alg-error class="dark small">
        <span i18n>You have no path to this content.</span>
      </alg-error>
    </ng-template>
  </ng-container>
</ng-container>
