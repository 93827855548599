<div class="input-wrapper" *ngIf="parentForm" [formGroup]="parentForm">
  <input
    class="input-element"
    #input
    [type]="inputType"
    [placeholder]="placeholder"
    [ngClass]="{ 'has-button': buttonIcon, 'has-input-icon': inputIcon }"
    [formControlName]="name"
    (focus)="focus.emit($event)"
    (blur)="blur.emit($event)"
    [pTooltip]="pTooltip"
    [tooltipEvent]="tooltipEvent"
    [tooltipPosition]="tooltipPosition"
    [tooltipDisabled]="tooltipDisabled"
  >
  <button
    class="icon-wrapper clear-button"
    type="button"
    (click)="clearInput(); input.focus()"
    tabindex="-1"
    *ngIf="hasClearButton && input.value"
  >
    <i class="icon ph ph-x"></i>
  </button>
  <span class="icon-wrapper" *ngIf="inputIcon">
    <i class="icon {{ inputIcon }}"></i>
  </span>
  <span *ngIf="buttonIcon" class="input-button" (click)="onButtonClick()">
    <i class="{{ buttonIcon }}"></i>
  </span>
</div>

<alg-input-error [form]="parentForm" [inputName]="name"></alg-input-error>
