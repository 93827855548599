<div class="container" #containerRef>
  <alg-observation-bar [onlyIcon]="!!(isNarrowScreen$ | async)" *ngIf="isObserving$ | async"></alg-observation-bar>
  <ng-container *ngrxLet="currentGroupContent$; let currentGroupContent">
    <!-- only works for non-user group (to be removed in favor of the second option) -->
    <button
      class="alg-button-icon no-bg primary-color start-watch-button"
      pButton
      type="button"
      icon="ph ph-eye"
      (click)="toggleObservationMode($event, containerRef, currentGroupContent)"
      *ngIf="currentGroupContent !== undefined && !currentGroupContent.isBeingWatched"
    ></button>
    <!-- only works for users for now -->
    <button
      class="alg-button-icon no-bg primary-color start-watch-button"
      pButton
      type="button"
      icon="ph ph-eye"
      (click)="toggleObservation(startObservingActiveContentGroupInfo)"
      *ngIf="startObservingActiveContentGroupInfo$ | async as startObservingActiveContentGroupInfo"
    ></button>
  </ng-container>
</div>
<p-overlayPanel
  #op
  [style]="{ minWidth: '15rem', maxWidth: '30rem' }"
  styleClass="alg-suggestion-of-activities-overlay"
  appendTo="body"
>
  <alg-suggestion-of-activities *ngIf="op.overlayVisible"></alg-suggestion-of-activities>
</p-overlayPanel>

