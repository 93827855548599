<div class="breadcrumb-container">
  <ul class="breadcrumb-list" *ngIf="contentBreadcrumb"> <!-- TODO: handle "smart" ellipsis (as in https://codepen.io/shippin/pen/YrwrYa) -->
    <!-- elements -->
    <ng-container *ngFor="let element of contentBreadcrumb.path; index as idx;">
      <li class="breadcrumb-item separator" [ngClass]="{ 'active': idx === contentBreadcrumb.currentPageIdx }" *ngIf="idx > 0">
        <i class="ph ph-caret-right separator-icon"></i>
      </li>
      <li
        class="breadcrumb-item"
        [ngClass]="{ 'active': idx === contentBreadcrumb.currentPageIdx, 'routable': element.navigateTo }"
        (click)="onElementClick(element)"
        [pTooltip]="element.title"
        tooltipPosition="bottom"
      >
        <span class="label">{{ element.title }}</span>
      </li>
    </ng-container>
  </ul>
</div>
