<div class="neighbor-widget" *ngIf="navigationMode">
  <div class="neighbor-widget-button" @buttonAnimation *ngIf="navigationMode.parent">
    <button
      pButton
      type="button"
      icon="ph-bold ph-caret-up"
      class="alg-button-icon no-bg primary-color"
      (click)="parent.emit()"
    ></button>
  </div>
  <div class="neighbor-widget-button">
    <button
      pButton
      type="button"
      icon="ph-bold ph-caret-left"
      class="alg-button-icon no-bg primary-color"
      (click)="left.emit()"
      [disabled]="!navigationMode.left"
    ></button>
  </div>
  <div class="neighbor-widget-button">
    <button
      pButton
      type="button"
      icon="ph-bold ph-caret-right"
      class="alg-button-icon no-bg primary-color"
      (click)="right.emit()"
      [disabled]="!navigationMode.right"
    ></button>
  </div>
</div>
