<div class="container" *ngIf="leftMenu$ | async as leftMenu">
  <div class="left-menu" [ngClass]="{ collapsed: !leftMenu.shown, animated: leftMenu.animated }">
    <alg-left-menu></alg-left-menu>
  </div>
  <ng-container *ngrxLet="{ fullFrameContentDisplayed: fullFrameContentDisplayed$, withLeftPaddingContentDisplayed: withLeftPaddingContentDisplayed$ } as layout">
    <ng-container *ngrxLet="isObserving$ as isObserving">
      <div class="right-container" [ngClass]="{ collapsed: !leftMenu.shown, animated: leftMenu.animated, 'thread-collapsed': !(isDiscussionVisible$ | async) }">
        <alg-top-bar
          [showBreadcrumbs]="!scrolled && !layout.fullFrameContentDisplayed && !(isNarrowScreen$ | async) && !!(canShowBreadcrumbs$ | async)"
          [modeBarDisplayed]="isObserving"
          [showLeftMenuOpener]="!!(canShowLeftMenu$ | async) && !leftMenu.shown"
          [showTopRightControls]="!!(showTopRightControls$ | async) && !(isNarrowScreen$ | async)"
        ></alg-top-bar>
        <div
          class="main-content-wrapper alg-flex-1"
          algHtmlElLoaded
          (elLoaded)="onLoaded($event)"
        >
          <div
            class="main-content"
            [ngClass]="{ 'with-left-padding': layout.withLeftPaddingContentDisplayed, 'full-frame': layout.fullFrameContentDisplayed }"
            id="main-container"
          >
            <div class="main-content-bottom-padding alg-flex-1">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div
    class="right-thread"
    [ngClass]="{ 'collapsed': !(isDiscussionVisible$ | async) }"
  >
    <alg-thread-container></alg-thread-container>
  </div>
</div>
<p-toast position="bottom-left"></p-toast>
<p-confirmDialog></p-confirmDialog>
<p-confirmPopup key="commonPopup" styleClass="common-confirm-popup"></p-confirmPopup>
<p-dialog
  *ngrxLet="fatalError$; let fatalError"
  i18n-header="@@auth-error-modal-title" header="Error"
  [visible]="true"
  [style]="{width: '50vw'}"
  [modal]="true"
  [closeOnEscape]="false"
  [closable]="false"
>
  <p class="error-message">
    <ng-container *ngIf="fatalError.message === 'ChunkError'; else otherErrorMessage" i18n>
      The application has been updated since you opened it, it needs to be reloaded.
    </ng-container>
    <ng-template #otherErrorMessage>
      <ng-container i18n="@@auth-error-modal-context">
        Oops, we are unable to make the site work properly. Are you connected to the Internet?
      </ng-container>
    </ng-template>
  </p>
  <div class="error-refresh-button">
    <p-button
      class="refresh-button"
      icon="pi pi-refresh"
      i18n-label label="Reload the application."
      (click)="onRefresh()"
      styleClass="p-button-sm"
    ></p-button>
  </div>
  <p class="error-note" i18n="@@contactUs">
    If the problem persists, please contact us.
  </p>
</p-dialog>
<alg-language-mismatch></alg-language-mismatch>
<p-dialog
  *ngIf="groupObservationError$ | async as groupObservationError"
  i18n-header header="Error"
  [visible]="showObservationErrorDialog"
  [modal]="true"
>
  @if(groupObservationError.isForbidden) {
    <ng-container i18n>You are not allowed to watch the group for which you have requested observation.</ng-container>
  } @else {
    <ng-container i18n>Something went wrong while enabling group observation. If the problem persists, please contact us.</ng-container>
  }

  <ng-template pTemplate="footer">
    <p-button
      i18n-label label="Close"
      (click)="closeObservationErrorDialog()"
    ></p-button>
  </ng-template>
</p-dialog>
